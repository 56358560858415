export const portals = {
  'main': ['Principal', 'principal'],
  'monitoraea': ['MonitoraEA', 'monitoraea'],
  'pp': ['Políticas Públicas', 'ppea'],
  'pppzcm': ['PPPZCM', 'pppzcm'],
};
export const content_types = {
  'news': ['Novidade','Novidades','novidades','Ver todas'],
  'learning': ['Processo formativo','Processos formativos','processos','Ver todos'],
  'publication': ['Publicação','Publicações','publicacoes','Ver todas'],
  'faq': ['Pergunta frequente','Perguntas frequentes'],
};